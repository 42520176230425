import React from "react";
import "./styles.scss";

const ServicesComponent = () => {
	return (
		<section className='services-component'>
			<div className='container services-intro'>
				<span className="font-size-sm gradient-text foundation-font">WHAT YOU GET</span>
				<h3 className='font-size-md main-text foundation-font mb-5'>
					<strong>Powerful services for your business</strong>
				</h3>
				<p className='main-p font-size-xs'>
					No matter which subscription package, we've got you covered.
				</p>
			</div>
			<div className='container'>
				<div className='row'>
					<div className='services-list'>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>SEO</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>Wireframing</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>Quality assurance</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>UI - User interface design</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>UX - User experience design</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>Mobile experience</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>Google analytics</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>Clear descriptions</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>Clear purpose</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>Strong professional branding</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>Call to action (CTA)</strong>
								</p>
							</div>
						</div>
						<div className='services-item text-start d-flex'>
							<span className='mdi mdi-check-bold me-3 align-self-start'></span>
							<div>
								<p className='foundation-font'>
									<strong>User friendly navigation</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServicesComponent;
