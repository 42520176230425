import React from "react";
import "./styles.scss";
import eShoez from "./images/e-shoez.png";
import cloudPixel from "./images/coud-pixel.png";
import happenz from "./images/happenz.png";
import woodyFurniture from "./images/woody-furniture.png";

const ProjectsComponent = () => {
	return (
		<section class='project-component container'>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='title-box text-center'>
						<span className='font-size-sm gradient-text foundation-font'>
							featured projects
						</span>
						<h3 className='font-size-md title-heading mb-5 foundation-font'>
							<strong>Our latest designs</strong>
						</h3>
						<p className='font-size-xs secondary-title'>
							Be one of the lucky ones and get started.
						</p>
						<div className='container-button d-flex justify-content-center'>
							<a
								target='_blank'
								href='https://www.figma.com/proto/yDHZZszxzPBgSgbA4BNPbw/cloud-pixel?node-id=1%3A8&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=1%3A8'
								className='foundation-font button button-1 me-3 aling-items-center'
								rel='noreferrer'
							>
								See projects
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className='row grid'>
				<div className='col-sm'>
					<img src={cloudPixel} />
				</div>
				<div className='col-sm'>
					<img src={happenz} />
				</div>
				<div className='col-sm'>
					<img src={eShoez} />
				</div>
				<div className='col-sm'>
					<img src={woodyFurniture} />
				</div>
			</div>
		</section>
	);
};

export default ProjectsComponent;
