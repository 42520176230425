import "./styles.scss";
import mainComp from "./images/main-comp.svg";
import React from "react";

function HomeComponent() {
	return (
		<section className='home-component mb-5' id='home'>
			<div className='container'>
				<div className='row d-flex justify-content-center'>
					<div className='col-sm-12 col-lg-6 align-self-center'>
						<h3 className='p2 font-size-sm'>
							The "drive thru" of software development.
						</h3>
						<h2 className='p1 font-size-lg foundation-font'>
							<strong>
								Thoughtfully <span className='gradient-text'>crafted</span>{" "}
								online <span className='gradient-text'>experiences</span>
							</strong>
						</h2>
						<h1 className='p2 font-size-sm'>
							Subscription-based custom design & development to skyrocket your
							business.
						</h1>
						<div className='container container-button d-flex align-items-center'>
							<a
								href='#pricing'
								className='foundation-font button button-1 me-3'
							>
								Get started
							</a>
							<a
								target='_blank'
								href='https://www.figma.com/proto/yDHZZszxzPBgSgbA4BNPbw/cloud-pixel?node-id=1%3A8&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=1%3A8'
								className='foundation-font button button-2 aling-items-center'
								rel='noreferrer'
							>
								<strong className='d-flex'>
									Latest projects{" "}
									<i className='mdi mdi-chevron-double-right'></i>
								</strong>
							</a>
						</div>
					</div>
					<div className='col-sm-10 col-lg-6'>
						<img
							alt='Computer Illustration'
							className='main-img'
							src={mainComp}
						/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeComponent;
