import "./App.css";
import "./fonts/foundation.ttf";
import "./fonts/Philosopher-Bold.ttf";
import PlansComponent from "./components/PlansComponent";
import BenefitsComponent from "./components/BenefitsComponent";
import FAQComponent from "./components/FAQComponent";
import NavbarComponent from "./components/NavbarComponent";
import HomeComponent from "./components/HomeComponent";
import RoadmapComponent from "./components/RoadmapComponent";
import ContactFormComponent from "./components/ContactFormComponent";
import FooterComponent from "./components/FooterComponent";
import ServicesComponents from "./components/ServicesComponent";
import WhyUsComponent from "./components/WhyUsComponent";
import ProjectsComponent from "./components/ProjectsComponent";

function App() {
	return (
		<div className='App'>
			<NavbarComponent />
			<HomeComponent />
			<RoadmapComponent />
			<BenefitsComponent />
			<WhyUsComponent />
			<ProjectsComponent />
			<ServicesComponents/>
			<PlansComponent />
			<ContactFormComponent />
			<FAQComponent />
			<FooterComponent />
		</div>
	);
}

export default App;
