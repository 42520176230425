import React from "react";
import "./styles.scss";

const ContactFormComponent = () => {
	return (
		<section id='contact' className='contact-form-component contact-section'>
			<div className='container'>
				<div className='row'>
					<div className='text-center'>
						<div className='contact-main'>
							<span className='font-size-sm gradient-text foundation-font'>GET IN TOUCH</span>
							<p className='font-size-md foundation-font'>
								<strong>Deliveries like you never seen before.</strong>
							</p>
							<span className="font-size-xs span">
								Want to know if Flowity AI is the right choice for you?
							</span>
							<br />
							<span className="font-size-xs span">Get a walk through to better understand our flow.</span>
						</div>
					</div>
					<div className='text-center'>
						<div className='info-wrap mt-3'>
							<div className='dbox dbox-1 d-flex align-items-center justify-content-center'>
								<div className='text text-start'>
									<p>
										<span className='mdi mdi-email pe-5'></span>
										<a href='mailto:admin@flowity.ai'> admin@flowity.ai</a>
									</p>
								</div>
							</div>
						</div>
						<div className='dbox-btn'>
							<a
								className='me-3'
								href='https://wa.me/5519994308383'
								target='_blank'
								rel='noreferrer'
							>
								<button className='foundation-font button button-1'>
									<strong>Send message</strong>{" "}
									<span className='mdi mdi-whatsapp'></span>
								</button>
							</a>
							<a
								href='#pricing'
								className='foundation-font button button-2 aling-items-center'
							>
								<strong className='d-flex'>
									Get Started <i className='mdi mdi-chevron-double-right'></i>
								</strong>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactFormComponent;
