import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./styles.scss";
import logo from "./images/flowity-logo.svg";

function NavbarComponent() {
	return (
		<Navbar expand="md" variant="dark" className='navbar-component'>
			<Container className="foundation-font">
				<Navbar.Brand href='/'>
					<img
						src={logo}
						height='80'
						alt='Flowity logo'
					/>&nbsp;
					<strong>FLOWITY AI</strong>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='navbar-nav' />
				<Navbar.Collapse id='navbar-nav'>
					<Nav className='navbar-links'>
						<Nav.Link href='#benefits'><strong>Benefits</strong></Nav.Link>
						<Nav.Link href='#pricing'><strong>Pricing</strong></Nav.Link>
						<Nav.Link href='#faq'><strong>FAQ</strong></Nav.Link>
					</Nav>
					<a className="navbar-btn" href='https://checkout.flowity.ai/p/login/9AQ4hz28q77O8EwfYY'><strong>Login</strong></a>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default NavbarComponent;
