import React from "react";
import aiVector from "./images/ai-vector.svg";
import fasterOrder from "./images/faster-order-processing.svg";
import figmaLogo from "./images/figma-logo.png";
import illustratorLogo from "./images/Illustrator-logo.png";
import photoshopLogo from "./images/photoshop-logo.png";
import freepikLogo from "./images/freepik-logo.png";
import flaticonLogo from "./images/flaticon-logo.png";
import zapierLogo from "./images/zapier-logo.png";
import stripeLogo from "./images/stripe-logo.png";
import slackLogo from "./images/slack-logo.png";
import trelloLogo from "./images/trello-logo.png";
import firebaseLogo from "./images/firebase-logo.png";

const WhyUsComponent = () => {
	return (
		<section className='why-us-component'>
			<div className='container'>
				<div className='grid grid-1 d-flex text-center row'>
					<div className='grid-card col-sm-12 col-lg-6 d-flex align-items-center'>
						<div className='container mb-992px-down'>
							<img src={aiVector} alt="AI vector"/>
						</div>
					</div>
					<div className='grid-card d-flex col-sm-12 col-lg-6'>
						<div className='container align-center align-center'>
							<span className='font-size-sm gradient-text foundation-font'>
								<strong>POWERED BY ARTIFICIAL INTELLIGENCE</strong>
							</span>
							<p className='font-size-md main-text foundation-font'>
								<strong>
									Live track everything directly from the platform.
								</strong>
							</p>

							<div className='scope-list'>
								<ul className='font-size-xs'>
									<li>Manage all your requests in one single place.</li>
									<li>Inclusive collaboration from start-to-end.</li>
									<li>
										More effectivess by commenting changes directly on the
										design.
									</li>
									<li>Put your team to work with us.</li>
									<li>Automations to make daily tasks easier.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className='container tools'>
					<div className='row mobile-logo-line1 mb-5'>
						<div className='col-sm d-flex align-items-center justify-content-around'>
							<img src={figmaLogo} alt="figma logo"/>
							<img src={illustratorLogo} alt="illustrator logo"/>
							<img src={photoshopLogo} alt="photoshop logo"/>
						</div>
						<div className='col-sm d-flex align-items-center justify-content-around'>
							<img src={flaticonLogo} alt="flat icon logo"/>
							<img src={freepikLogo} alt="freepik logo"/>
						</div>
					</div>
					<div className='row logo-line1 d-flex align-items-center justify-content-center mb-5'>
						<div className='col-sm-4'>
							<img src={figmaLogo} alt="figma logo"/>
						</div>
						<div className='col-sm-4'>
							<img src={illustratorLogo} alt="illustrator logo"/>
						</div>
						<div className='col-sm-4'>
							<img src={photoshopLogo} alt="photoshop logo" />
						</div>
						<div className='col-sm-4'>
							<img src={flaticonLogo} alt="flat icon logo"/>
						</div>
						<div className='col-sm-4'>
							<img src={freepikLogo} alt="freepik logo"/>
						</div>
					</div>
					<div className='row mb-5'>
						<div className='col-sm'>
							<p className='font-size-md foundation-font'>
								<strong>
									We work only with the <span>greatest</span>, <br /> to give{" "}
									<span>you</span> the <span>best</span>.
								</strong>
							</p>
						</div>
					</div>
					<div className='row mobile-logo-line2 mb-5'>
						<div className='col-sm d-flex align-items-center justify-content-around'>
							<img src={zapierLogo} alt="zapier logo"/>
							<img className='mt-3' src={stripeLogo} alt="stripe logo"/>
							<img src={slackLogo} alt="slack logo"/>
						</div>
						<div className='col-sm d-flex align-items-center justify-content-around'>
						<img src={trelloLogo} alt="trello logo"/>
						<img src={firebaseLogo} alt="firebase logo"/>
					</div>
					</div>
					<div className='row logo-line2 d-flex align-items-center justify-content-center mb-5'>
						<div className='col-sm-4'>
							<img src={zapierLogo} alt="zapier logo"/>
						</div>
						<div className='col-sm-4'>
							<img className='mt-3' src={stripeLogo} alt="stripe logo"/>
						</div>
						<div className='col-sm-4'>
							<img src={slackLogo} alt="slack logo"/>
						</div>
						<div className='col-sm-4'>
							<img src={trelloLogo} alt="trello logo"/>
						</div>
						<div className='col-sm-4'>
							<img src={firebaseLogo} alt="firebase logo"/>
						</div>
					</div>
				</div>

				<div className='grid grid-1 d-flex text-center row'>
					<div className='grid-card d-flex col-sm-12 col-lg-6'>
						<div className='container align-center align-center'>
							<span className='font-size-sm gradient-text font-size-30 foundation-font'>
								<strong>FASTER ORDER PROCESSING</strong>
							</span>
							<p className='font-size-md main-text foundation-font font-size-40'>
								<strong>
									Packages that will get your business up to the sky.
								</strong>
							</p>

							<div className='scope-list'>
								<ul className='font-size-xs'>
									<li>Backlog and queue tasks.</li>
									<li>Unlimited design.</li>
									<li>Unlimited development support.</li>
									<li>Unlimited revisions.</li>
									<li>Unlimited stock images.</li>
									<li>Custom illustrations and icons.</li>
								</ul>
							</div>
						</div>
					</div>
					<div className='grid-card col-sm-12 col-lg-6 d-flex align-items-center'>
						<div className='container mt-992px-down'>
							<img src={fasterOrder} alt="Faster order processing"/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WhyUsComponent;
