export const Data = [
	{
		question: "How to manage my subscription? How to login?",
		answer:
			"Our login is exclusively to paying customers. Once the payment is confirmed you can login and access your dashboard to manage it.",
	},
    {
        question: "When can I start requesting my designs?",
        answer: 'Once payment if confirmed you will, within the next 24h, receive an e-mail to access your Trello board where you can start requesting your designs immidiately.'
    },
	{
		question: "How fast is my project going to be ready?",
		answer:
			"It usually takes up between 2-3 business days, on average, per design task. The development can take up to a week, on average, then we run a quality assurance testing that can also take up to a week, always depending on the size of the project.",
	},
	{
		question: "Are there any refund options?",
		answer:
			"We'll revise your design until 100% satisfaction and if refund is requested, it will be examined case by case but no refunds are guaranteed.",
	},
	{
		question: 'What does "unlimited" really means?',
		answer:
			"It means that you will have a backlog list where you can request as many designs as you want, the tasks will enter in a queue and will be done one by one.",
	},
	{
		question: 'How do revisions work?',
		answer:
			"Revisions are not the same as active requests and will be made over the tasks that are already finished. Revisions entail small changes or fixes on the chosen layout. If you want to completely change the layout it will be a new task, which means it will still be the actice request or it will go back into the queued tasks to be finished later, depending on each situation.",
	},
	{
		question: "What if I'm no longer active and I still have revisions to be made?",
		answer:
			"Don't worry, revisions will be made even if you cancel your monthly subscription. However, if your revision needs an update or a new layout is requested, it can only be made by an active subscription.",
	},
	{
		question: "I don't have any design needs anymore. Can I just have monthly maintenance on the development?",
		answer:
			"If you have an active subscription you will have maintenance and much more available. Unfortunately, we don't offer just small maintenance of the development process.",
	},
];
