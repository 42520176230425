import React from "react";
import "./styles.scss";

import step1 from "./images/step1.png";
import step2 from "./images/step2.png";
import step3 from "./images/step3.png";
import step4 from "./images/step4.png";

const RoadmapComponent = () => {
	return (
		<section className='roadmap-component' id='roadmap'>
			<div className='container'>
				<div className='d-flex justify-content-center d-flex'>
					<div className='main-container col-sm-10'>
						<span className='font-size-sm main-span col-lg-10 gradient-text foundation-font'>HOW IT WORKS</span>
						<p className='font-size-md main-p foundation-font mb-5 col-lg-10'>
							<strong>Our process to make it happen for you</strong>
						</p>
						<p className='font-size-xs secondary-p col-lg-10'>
							<br /> Request &#62; Receive &#62; Review &#62; Repeat. <br /> Be responsible for only requesting and reviewing.
						</p>
					</div>
				</div>
				<div className='row d-flex align-items-center'>
					<div className='col-sm-12'>
						<div className='container'>
							<div className='row grid justify-content-center'>
								<div className='card p-4'>
									<div className='container'>
										<div className='image'>
											<img src={step1} alt="step 1"/>
										</div>
										<p className='text-gradient d-flex justify-content-center'>
											<strong className="font-size-md foundation-font">STEP</strong>
											<span className='mdi mdi-roman-numeral-1'></span>
										</p>
										<p className='p1 font-size-xs'>
											Subscribe to a plan and start requesting.
										</p>
									</div>
								</div>
								<div className='card p-4'>
									<div className='container'>
										<div className='image'>
											<img src={step2} alt="tep 2"/>
										</div>
										<p className='text-gradient d-flex justify-content-center'>
											<strong className="font-size-md foundation-font">STEP</strong>
											<span className='mdi mdi-roman-numeral-2'></span>
										</p>
										<p className='p1 font-size-xs'>
											LIVE track each step of the development process of your
											project.
										</p>
									</div>
								</div>
								<div className='card p-4'>
									<div className='container'>
										<div className='image d-flex justify-content-center'>
											<img src={step3} alt="step 3"/>
										</div>
										<p className='text-gradient d-flex justify-content-center'>
											<strong className="font-size-md foundation-font">STEP</strong>
											<span className='mdi mdi-roman-numeral-3'></span>
										</p>
										<p className='p1 font-size-xs'>
											Only a couple of days away to get your prototype, on
											average.
										</p>
									</div>
								</div>
								<div className='card p-4'>
									<div className='container'>
										<div className='image'>
											<img src={step4} alt="step 4"/>
										</div>
										<p className='text-gradient d-flex justify-content-center'>
											<strong className="font-size-md foundation-font">STEP</strong>
											<span className='mdi mdi-roman-numeral-4'></span>
										</p>
										<p className='p1 font-size-xs'>
											Revisions will be made until you are 100% satisfied.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RoadmapComponent;
