import React from "react";
import "./styles.scss";
import logo from "./images/flowity-logo.svg";

const FooterComponent = () => {
	return (
		<section className='footer-component'>
			<footer className='container'>
				<div className='row d-flex'>
					<div className='logo col-sm text-start mb-4 foundation-font font-size-xs'>
						<img src={logo} width="60" alt='Flowity AI logo' />
						&nbsp; Flowity AI
					</div>
					<div className='col text-start font-size-xs'>
						<ul>
							<li>
								<a href='#benefits'>Benefits</a>
							</li>
							<li>
								<a href='#roadmap'>Roadmap</a>
							</li>
							<li>
								<a href='#pricing'>Pricing</a>
							</li>
							<li>
								<a href='#faq'>FAQs</a>
							</li>
							<li>
								<a href='#contact'>Contact</a>
							</li>
						</ul>
					</div>
					<div className='col text-start font-size-xs'>
						<ul>
							<li>
								<a target="_blank" rel="noreferrer" href='https://checkout.flowity.ai/p/login/9AQ4hz28q77O8EwfYY'>
									Client Login
								</a>
							</li>
							<li>
								<a target="_blank" rel="noreferrer" href='https://jeweled-branch-667.notion.site/Terms-and-conditions-of-FLOWITY-AI-f8b6e82af20a40ebbb8b5630e7bd45b3'>Terms and conditions</a>
							</li>
							<li>
								<a target="_blank" rel="noreferrer" href='https://www.notion.so/Privacy-policy-of-FLOWITY-AI-6f22759a4a7e4e91b47483c8dbf6d9ca'>Privacy policy</a>
							</li>
						</ul>
					</div>
				</div>
				<div className='row rights mt-5 pb-5 font-size-xs'>
					<div className='col'>
						Thoughfully crafted by <a href="#">FLOWITY AI</a> &copy; 2023
					</div>
				</div>
			</footer>
		</section>
	);
};

export default FooterComponent;
