import React from "react";
import "./styles.scss";
import chatSVG from "./images/011-chat.svg";
import wwwSVG from "./images/021-www.svg";
import startSVG from "./images/024-star.svg";
import sharedSVG from "./images/035-shared.svg";
import creativeSVG from "./images/043-creative-idea.svg";
import paymentSVG from "./images/048-payment-method.svg";

const BenefitsComponent = () => {
	return (
		<section className='benefits-component' id='benefits'>
			<div className='container'>
				<span className='font-size-sm gradient-text foundation-font'>WHAT WE OFFER</span>
				<div className='d-flex justify-content-center'>
					<p className='font-size-md main-text foundation-font col-lg-6'>
						<strong>Benefits we provide to give you the best</strong>
					</p>
				</div>
				<div className='grid grid-1 d-flex justify-content-between text-center'>
					<div className='grid-card mb-5'>
						<img src={chatSVG} width='40' alt="chat-svg" />
						<div className='container'>
							<p className='mb-1 purple foundation-font font-size-sm gradient-text'>
								<strong>Easy request</strong>
							</p>
							<span className='benefit font-size-xs'>
								Add as many info about your project as needed.
							</span>
						</div>
					</div>
					<div className='grid-card mb-5'>
						<img src={wwwSVG} width='40' alt="www-svg" />
						<div className='container'>
							<p className='font-size-sm gradient-text mb-1 purple foundation-font font-size-22'>
								<strong>'Drive thru' delivery</strong>
							</p>
							<span className='font-size-xs'>
								Get you first prototype in just a couple of days.
							</span>
						</div>
					</div>
					<div className='grid-card mb-5'>
						<img src={paymentSVG} width='40' alt="payment-svg"/>
						<div className='container'>
							<p className='font-size-sm gradient-text foundation-font mb-1 purple'>
								<strong>Transparent pricing</strong>
							</p>
							<span className='font-size-xs'>
								No surprises here. We have fixed prices for the projects.
							</span>
						</div>
					</div>

					<div className='grid-card mb-5'>
						<img src={startSVG} width='40' alt="start-svg"/>
						<div className='container'>
							<p className='font-size-sm gradient-text foundation-font mb-1 purple'>
								<strong>Top-notch quality</strong>
							</p>
							<span className='font-size-xs'>
								Thoughtfully crafted online experiences for your business.
							</span>
						</div>
					</div>
					<div className='grid-card mb-5'>
						<img src={sharedSVG} width='35' alt="shared-svg"/>
						<div className='container'>
							<p className='font-size-sm gradient-text foundation-font mb-1 purple'>
								<strong>Flexible and scalable</strong>
							</p>
							<span className='font-size-xs'>
								Easily request changes and improvements as wanted.
							</span>
						</div>
					</div>
					<div className='grid-card mb-5'>
						<img src={creativeSVG} width='30' alt="creative-svg"/>
						<div className='container'>
							<p className='font-size-sm gradient-text foundation-font mb-1 purple'>
								<strong>Unique designs</strong>
							</p>
							<span className='font-size-xs'>
								All designs made especially to meet your needs and taste.
							</span>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BenefitsComponent;
