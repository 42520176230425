import React from "react";
import "./styles.scss";
import starter from "./images/starter.svg";
import plus from "./images/plus.svg";
import premium from "./images/premium.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function PlansComponent() {
	const renderTooltip = (props) => (
		<Tooltip id='button-tooltip' {...props}>
			Newsletter, brochures, social media posts, branding, business cards,
			digital ads, billboards, etc.
		</Tooltip>
	);

	return (
		<section className='container plans-component' id='pricing'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-12'>
						<div className='title-box text-center'>
							<span className='font-size-sm gradient-text foundation-font'>
								DON'T WAIT UP
							</span>
							<h3 className='font-size-md title-heading mb-5 foundation-font'>
								<strong>Subscription packages</strong>
							</h3>
							<p className='font-size-xs secondary-title'>
								Get the consistency and experience of a company with the
								flexibility and price of a freelancer, every single month.
							</p>
						</div>
					</div>
				</div>
				<div className='container'>
					<div className='row'>
						<div className='col-sm-12 col-lg-4 mb-4'>
							<div className='pricing-box mt-4 card card1'>
								<img src={starter} alt='Starter subscription image' />
								<h4 className='font-size-md foundation-font purple'>
									<strong>Starter</strong>
								</h4>
								<div className='row pricing-plan mt-4 pt-2'>
									<div className='col-sm'>
										<div>
											<p className='plan font-size-lg'>
												$2,995
												<span className='text-muted font-size-xs'>
													&nbsp; /m
												</span>
											</p>
										</div>
									</div>
								</div>
								<div className='row mt-3 pt-2 text-start'>
									<div className='sub-details col-sm font-size-xs'>
										<span className='text-muted'>Design</span>

										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Landing pages</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Mobile-Desktop screen sizes</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Ready-to-use icons</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Stock images</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Ready-to-use illustrations</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Multiple-pages website</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Custom Icons</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Custom Illustrations</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Dashboard</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp;
											<OverlayTrigger
												placement='right'
												delay={{show: 250, hide: 400}}
												overlay={renderTooltip}
											>
												<div className=''>
													<p className=''>
														General design{" "}
														<i className='mdi mdi-alert-circle-outline'></i>
													</p>
												</div>
											</OverlayTrigger>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Slack team support</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>E-commerce</p>
										</p>

										<br />

										<span className='text-muted'>Development</span>

										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Landing pages</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Mobile-Desktop resposiveness</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Updates</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Adjustments</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Simple API</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Advanced API</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Database</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Slack team support</p>
										</p>
									</div>
								</div>

								<div className='bottom-button mt-4 pt-3'>
									<a
										href='https://buy.stripe.com/8wM7t5cKN94FaXK7ss'
										className='foundation-font button button-1'
									>
										Get started
									</a>
								</div>
							</div>
						</div>
						<div className='col-sm-12 col-lg-4 mb-4'>
							<div className='pricing-box mt-4 card card2'>
								<img src={plus} alt='Plus subscription image' />
								<h4 className='font-size-md foundation-font purple'>
									<strong>Plus</strong>
								</h4>
								<div className='row pricing-plan mt-4 pt-2'>
									<div className='col-sm'>
										<div>
											<p className='plan font-size-lg'>
												$4,795
												<span className='text-muted font-size-xs'>
													&nbsp; /m
												</span>
											</p>
										</div>
									</div>
								</div>
								<div className='row mt-4 pt-2 text-start'>
									<div className='sub-details col-sm font-size-xs'>
										<span className='text-muted'>Design</span>

										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Landing pages</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Mobile-Desktop screen sizes</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Ready-to-use icons</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Stock images</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Ready-to-use illustrations</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Multiple-pages website</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Custom Icons</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Custom Illustrations</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Dashboard</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp;
											<OverlayTrigger
												placement='right'
												delay={{show: 250, hide: 400}}
												overlay={renderTooltip}
											>
												<div className=''>
													<p className=''>
														General design{" "}
														<i className='mdi mdi-alert-circle-outline'></i>
													</p>
												</div>
											</OverlayTrigger>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Slack team support</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>E-commerce</p>
										</p>

										<br />

										<span className='text-muted'>Development</span>

										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Landing pages</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Mobile-Desktop resposiveness</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Updates</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Adjustments</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Simple API</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Advanced API</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Database</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-close-circle'></i>
											&nbsp; <p>Slack team support</p>
										</p>
									</div>
								</div>

								<div className='bottom-button mt-4 pt-3'>
									<a
										href='https://buy.stripe.com/7sI6p1125dkVe9W9AB'
										className='foundation-font button button-1'
									>
										Get started
									</a>
								</div>
							</div>
						</div>

						<div className='col-sm-12 col-lg-4 mb-4'>
							<div className='pricing-box mt-4 card card3'>
								<img src={premium} alt='Premium subscription image' />
								<h4 className='font-size-md foundation-font purple'>
									<strong>Premium</strong>
								</h4>
								<div className='row pricing-plan mt-4 pt-2'>
									<div className='col-sm'>
										<div>
											<p className='plan font-size-lg'>
												$6,795
												<span className='text-muted font-size-xs'>
													&nbsp; /m
												</span>{" "}
											</p>
										</div>
									</div>
								</div>
								<div className='row mt-4 pt-2 text-start'>
									<div className='sub-details col-sm font-size-xs'>
										<span className='text-muted'>Design</span>

										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Landing pages</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Mobile-Desktop screen sizes</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Ready-to-use icons</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Stock images</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Ready-to-use illustrations</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Multiple-pages website</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Custom Icons</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Custom Illustrations</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Dashboard</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp;
											<OverlayTrigger
												placement='right'
												delay={{show: 250, hide: 400}}
												overlay={renderTooltip}
											>
												<div className=''>
													<p className=''>
														General design{" "}
														<i className='mdi mdi-alert-circle-outline'></i>
													</p>
												</div>
											</OverlayTrigger>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Slack team support</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>E-commerce</p>
										</p>

										<br />

										<span className='text-muted'>Development</span>

										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Landing pages</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Mobile-Desktop resposiveness</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Updates</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Adjustments</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Simple API</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Advanced API</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Database</p>
										</p>
										<p className='d-flex'>
											<i className='mdi mdi-checkbox-marked-circle purple'></i>
											&nbsp; <p>Slack team support</p>
										</p>
									</div>
								</div>

								<div className='bottom-button mt-4 pt-3'>
									<a
										href='https://buy.stripe.com/3cs7t5269gx71na6oq'
										className='foundation-font button button-1'
									>
										Get started
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default PlansComponent;
